import useScrollPosition from '@react-hook/window-scroll'
import { SupportedChainId } from 'constants/chains'
import { useEffect } from 'react'
import styled from 'styled-components/macro'
import { switchToNetwork } from 'utils/switchToNetwork'

import { STATIC_URL } from '../../config'
import { useActiveWeb3React } from '../../hooks/web3'
import { useWindowSize } from '../../hooks/useWindowSize'
import Menu from '../Menu'
import Web3Status from '../Web3Status'
import NetworkSelector from './NetworkSelector'

const HeaderFrame = styled.div<{ showBackground: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  top: 0;
  position: relative;
  padding: 0 1rem;
  height: 48px;
  z-index: 21;
  position: relative;
  /* Background slide effect on scroll. */
  background-image: ${({ theme }) => `linear-gradient(to bottom, transparent 50%, ${theme.bg0} 50% )}}`};
  background-position: ${({ showBackground }) => (showBackground ? '0 -100%' : '0 0')};
  background-size: 100% 200%;
  box-shadow: 0px 0px 0px 1px ${({ theme, showBackground }) => (showBackground ? theme.bg2 : 'transparent;')};
  transition: background-position 0.1s, box-shadow 0.1s;
  background-blend-mode: hard-light;
  background: #1c1c29;
  box-shadow: 0px -1px 0px 0px #2d2d3e inset;
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: 0.3em;
  }

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 6px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
  `};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg1)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;

  :focus {
    border: 1px solid blue;
  }
`
const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`
const HeaderLeftHr = styled.hr`
  width: 1px;
  height: 24px;
  border: none;
  background: #2d2d3e;
`

const HeaderNavLayout = styled.nav`
  display: flex;
  align-items: center;
  gap: 24px;
`

const Logo = styled.a`
  width: 80px;
  height: 30px;
  display: block;
  background: url(${STATIC_URL}/swap/logo.svg) center/72px auto no-repeat;
`

const NavItem = styled.a`
  color: #6f6e86;
  padding: 6px 12px;
  border-radius: 8px;

  &.active {
    color: #ffffff;
    background: #303046;
    font-weight: bold;
  }
`

export default function Header() {
  const { account, chainId, library } = useActiveWeb3React()

  const scrollY = useScrollPosition()
  const size = useWindowSize()

  useEffect(() => {
    if (library && chainId !== SupportedChainId.BNB) void switchToNetwork({ library, chainId: SupportedChainId.BNB })
  }, [chainId, library])

  return (
    <HeaderFrame showBackground={scrollY > 45}>
      <HeaderLeft>
        <Logo href="/" />
        {Number(size.width) > 960 ? (
          <>
            <HeaderLeftHr />
            <Menu />
            <HeaderLeftHr />

            <HeaderNavLayout>
              <NavItem className="active" href="/swap">
                Swap
              </NavItem>
              <NavItem href="/">Perpetuals</NavItem>
              <NavItem href="/">Earn</NavItem>
              <NavItem href="/">Launchpad</NavItem>
            </HeaderNavLayout>
          </>
        ) : null}
      </HeaderLeft>
      <HeaderControls>
        <HeaderElement>
          <NetworkSelector />
        </HeaderElement>
        <HeaderElement>
          <AccountElement active={!!account}>
            <Web3Status />
          </AccountElement>
        </HeaderElement>
        {Number(size.width) <= 960 ? (
          <HeaderElement>
            <Menu />
          </HeaderElement>
        ) : null}
      </HeaderControls>
    </HeaderFrame>
  )
}
